'use client';

/**
 * @see https://github.com/vercel/next.js/blob/v14.0.5-canary.41/packages/third-parties/src/google/ga.tsx
 */
import React, { useEffect, useState } from 'react';

import Script from 'next/script';

import { IS_INTERNAL_KEY } from '@/constants';

export type GoogleAnalyticsProps = {
  gaId: string;
  dataLayerName?: string;
};

export default function GoogleAnalytics({
  gaId,
  dataLayerName = 'dataLayer',
}: GoogleAnalyticsProps) {
  const [script, setScript] = useState(false);

  useEffect(() => {
    // Only load analytics on the production hostname
    if (
      window.location.hostname === process.env.NEXT_PUBLIC_DOMAIN &&
      window.localStorage.getItem(IS_INTERNAL_KEY) !== 'true'
    ) {
      setScript(true);
    }
  }, []);

  if (!script) {
    return false;
  }

  return (
    <>
      <Script
        id="_next-ga-init"
        dangerouslySetInnerHTML={{
          __html: `
          window['${dataLayerName}'] = window['${dataLayerName}'] || [];
          function gtag(){window['${dataLayerName}'].push(arguments);}
          gtag('js', new Date());

          gtag('config', '${gaId}');`,
        }}
      />
      <Script id="_next-ga" src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`} />
    </>
  );
}

'use client';

/**
 * Inspired by Google Analytics script.
 */
import React, { useEffect, useState } from 'react';

import Script from 'next/script';

import { IS_INTERNAL_KEY } from '@/constants';

export type MicrosoftClarityProps = {
  id: string;
};

export default function MicrosoftClarity({ id }: MicrosoftClarityProps) {
  const [script, setScript] = useState(false);

  useEffect(() => {
    // Only load analytics on the production hostname
    if (
      window.location.hostname === process.env.NEXT_PUBLIC_DOMAIN &&
      window.localStorage.getItem(IS_INTERNAL_KEY) !== 'true'
    ) {
      setScript(true);
    }
  }, []);

  if (!script) {
    return false;
  }

  return (
    <Script
      id="_next-clarity-init"
      dangerouslySetInnerHTML={{
        __html: `
          (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${id}");
          `,
      }}
    />
  );
}
